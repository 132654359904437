<template>
<v-card flat class="app-background" height="100%">
  <v-card class="card-background rounded-lg">
    <v-container fill-height fluid class="ma-0 pa-0">
        <v-row align="center" justify="center" no-gutters>
            <v-col align="center" justify="center" no-gutters>
                <p class="welcome">Welcome to Your Virtual Meeting</p>
                <v-row class="card-header" align="center" justify="center" no-gutters>
                    <span class="quick-start">Please wait for the host to start this meeting</span>
                </v-row>
                <v-col class="ma-0 pa-0">
                    <p class="form-title">Meeting Starts in</p>
                    <p class="form-title">{{ countdown }}</p>
                    <div style="height: 30px;"/>
                    <v-icon size="60" class="circle-ripple" color="#00cf4a">
                        {{"mdi-check-circle"}}
                    </v-icon>

                    <div style="height: 40px;"/>
                    <p class="center-text">Start Time: {{ this.$store.state.tokenInfo.availableStartTime }}</p>
                    <p class="center-text">Subject: {{ this.$store.state.tokenInfo.subject }}</p>

                    <div style="height: 40px;"/>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
  </v-card>
</v-card>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'Waiting',

        components: {

        },

        data() {
            return {
                startTime: this.$store.state.tokenInfo.availableStartTime,

                interval: null,
                countdown: "00:00:00",
            };
        },

        mounted() {
            this.update();
            this.interval = setInterval(this.update, 1000);
        },

        beforeDestroy: function() {
            clearInterval(this.interval);
        },

        methods: {
            update() {
                var start = moment(this.startTime, "YYYY/MM/DD HH:mm:ss");
                var now = moment();

                if(now.isBefore(start)){
                    var diff = start.diff(now);
                    this.countdown = Math.floor(moment.duration(diff).asHours()) + ':' + moment.utc(diff).format('mm:ss');
                }else{
                    const urlParams = new URLSearchParams(window.location.search);
                    this.$router.push(urlParams.get('redirect'));
                    //this.$router.push(this.$route.query.redirect); //this way maybe better
                }
            },
        }
    };
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

    .app-background{
        background-image: linear-gradient(to bottom, #fbfbfb, #f4f8fc);
    }

    .card-background {
        height: 100%;
        margin: 32px 28px 0px 28px;
        background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
    }

    .welcome {
        padding: 0px;
        margin: 0 0 48px 0;
        font-size: 35px;
        font-family: 'Lato';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
    }

    .card {
        width: 45%;
        padding: 0;
        margin: 0;
    }

    .card-header {
        padding: 0;
        margin: 0;
        height: 5%;
    }

    .quick-start {
        padding: 0px;
        margin: 0 0 27px 0;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #007fb2;
    }

    .form-row {
        padding: 0px;
        margin: 0px;

    }

    .form-title {
        padding: 0px;
        margin: 0 0 10px 0;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #606060;
    }

    .center-text {
        padding: 0px;
        margin: 0 0 7px 0;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
        text-align: center;
    }

    .circle-ripple {
        width: 50px;
        height: 50px;
        margin: 23px 0 40px 0;
        border-radius: 50%;
        animation: ripple 3s linear infinite;
    }

    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 rgba(0, 207, 74, 0.3),
                        0 0 0 0px rgba(0, 207, 74, 0.3);
        }
        25% {
            box-shadow: 0 0 0 0 rgba(0, 207, 74, 0.3),
                        0 0 0 30px rgba(0, 207, 74, 0.3);
        }
        50% {
            box-shadow: 0 0 0 60px rgba(0, 207, 74, 0),
                        0 0 0 60px rgba(0, 207, 74, 0);
        }
    }

    @media screen and (max-width: 960px) {
        .card-background {
            background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
            height: 100%;
            margin: 0px;
        }

        .card {
            width: 90%;
            padding: 0;
            margin: 0;
        }

        .welcome {
            padding: 0px;
            margin: 0 0 48px 0;
            font-size: 20px;
            font-family: 'Lato';
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
        }

        .quick-start {
            padding: 0px;
            margin: 0 0 27px 0;
            font-size: 18px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #007fb2;
        }

        .form-title {
            padding: 0px;
            margin: 0 0 10px 0;
            font-size: 14px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #606060;
        }

        .center-text {
            padding: 0px;
            margin: 0 0 7px 0;
            font-size: 14px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
            text-align: center;
        }
    }
</style>
